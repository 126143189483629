<template>
  <fw-layout
    back-to="/"
    mobile-ready
    :loading="loading"
    :main-sidebar="false"
    loading-title="UC Competitions"
    :show-right-sidebar="chatIsOpen"
    :right-sidebar-width="chatIsOpen ? 'w-96' : null"
  >
    <template #main-content>
      <div v-if="team && !loading" class="xl:max-w-screen-lg mx-auto px-2 md:px-5 my-5">
        <TeamHeader
          :team="team"
          :sport="sport"
          :edition="edition"
          :tournament="tournament"
          :league="league"
          :can-submit="validations.can_submit"
          :can-edit="validations.can_edit_team"
          @submit-team="submitTeamConfirm()"
        />
        <PanelTeam
          :show-header="false"
          :team="team"
          :users="users"
          :messages="messages"
          :pagination-messages="pagination"
          :loading-messages="loadingMessages"
          :validations="validations"
          :sport="sport"
          @add-player="openModal"
          @delete-player="deletePlayer"
          @set-leader="setLeader"
          @resend-invite="resendInvite"
          @cancel-team-enrollment="cancelTeamConfirm"
          @add-message="addMessage"
        ></PanelTeam>
      </div>
      <LoadingPlaceholder v-else-if="loading" />
      <div v-else-if="!loading" class="py-5 text-center text-gray-500">Equipa não encontrada</div>
    </template>

    <template v-if="canSeeChat" #header-after>
      <div>
        <fw-button
          :type="chatIsOpen ? 'light-primary' : 'xlight'"
          :loading="loading"
          label="Canal de conversação"
          class="ml-auto"
          @click.native="chatIsOpen = !chatIsOpen"
        >
          <fw-icon-chats class="w-5 h-5" />
        </fw-button>
      </div>
    </template>

    <template v-if="canSeeChat && chatIsOpen" #right-sidebar>
      <PanelChats
        minimal
        :show-uploader="false"
        :chats="chats"
        :chat-users="chatUsers"
        :all-chat-users="allChatUsers"
        :chat-active="chatActive"
        :editable="true"
        :view-chats-list="false"
        :allow-downloads="true"
        :load-chat-on-create="true"
        @load-chat="loadChat"
        @load-main-chat="loadMainChat"
        @unload-chat="unloadChat"
        @set-chat="setChat"
        @set-chat-messages="setChatMessages"
        @delete-chat-message="deleteChatMessage"
      />
    </template>

    <template #modals>
      <fw-modal :active.sync="isModalActive" size="md" boxed="xs" @close="closeModal">
        <fw-panel :title="'Adicionar membro de equipa'" class="m-5">
          <form class="mb-3" @submit.prevent="addTeamMember">
            <fw-label
              >Indique um email (externo ou institucional) da pessoa que pretende convidar para a sua equipa.</fw-label
            >
            <b-input v-model="tmpTeamMember" class="flex-1"></b-input>
            <fw-tip v-if="$v.tmpTeamMember.$error" error>
              <span v-if="!$v.tmpTeamMember.isUnique">O e-mail já faz parte dos convites definidos.</span>
              <span v-else-if="!$v.tmpTeamMember.required">Insira um e-mail da pessoa que pretende convidar.</span>
              <span v-else>O e-mail não é válido.</span>
            </fw-tip>
            <fw-tip v-if="errorMessage" error>{{ errorMessage }}</fw-tip>
          </form>

          <div class="flex items-center justify-between">
            <fw-button type="link-muted" :disabled="saving" @click.native="closeModal">
              Fechar
            </fw-button>
            <fw-button type="primary" :loading="saving" @click.native="addTeamMember">
              Adicionar novo membro
            </fw-button>
          </div>
        </fw-panel>
      </fw-modal>
    </template>
  </fw-layout>
</template>
<script>
import PanelTeam from '@/components/panels/PanelTeam.vue'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import TeamHeader from '@/components/header/TeamHeader.vue'
import PanelChats from '@/fw-modules/fw-core-vue/chats/components/panels/PanelChats'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ChatLive from '@/fw-modules/fw-core-vue/chats/mixins/ChatLive'

import { required, email } from 'vuelidate/lib/validators'
export default {
  components: {
    PanelTeam,
    LoadingPlaceholder,
    TeamHeader,
    PanelChats,
  },

  mixins: [ChatLive],

  data() {
    const data = this.getChatDefaultData()
    data.loading = true
    data.loadChatOnCreate = true
    data.destroying = false
    data.viewChatsList = true
    return {
      chatIsOpen: false,
      loading: true,
      team: null,
      loadingMessages: false,
      messages: [],
      users: {},
      sport: {},
      pagination: {
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 50,
      },
      isModalActive: false,
      tmpTeamMember: '',
      errorMessage: null,
      saving: false,
      //missing info from backend
      edition: {},
      league: {},
      tournament: {},
      validations: {
        can_send_message: false,
        can_send_public_message: false,
        can_cancel_team_enrollment: false,
        can_edit_team: false,
        can_see_messages: false,
        can_resend_invites: false,
        can_send_invites: false,
        can_submit: false,
      },
      ...data,
    }
  },
  validations() {
    return {
      tmpTeamMember: {
        required,
        valid: email,
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (value === '') return true

          const existPlayer = this.team.players.find(el => el.email == value || (el.number && el.number == value))
          const existInvite = this.team.invites.find(el => el.email == value || (el.number && el.number == value))
          return !existPlayer && !existInvite
        },
      },
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    api() {
      return this.$store.state.api.base
    },
    tournamentKey() {
      return this.$route.params.tournamentKey
    },
    teamKey() {
      return this.$route.params.teamKey
    },
    canSeeChat() {
      return false
      // return this.team?.chat && this.team.leader.key === this.user.key
    },
  },

  beforeDestroy() {
    this.destroying = true
    this.unregisterChat()
  },

  created() {
    this.registerChat()
  },

  mounted() {
    this.getTeam()
    this.getTournament()
  },
  methods: {
    isInChatView() {
      return !this.destroying
    },

    setLeader(player) {
      this.team.leader = player
      this.updateTeam()
    },

    async addTeamMember() {
      this.$v.$touch()
      if (this.$v.tmpTeamMember.$invalid) return

      await this.updateTeam(this.tmpTeamMember)
      this.$v.tmpTeamMember.$reset()
      if (this.errorMessage) return
      this.tmpTeamMember = ''
      this.closeModal()
    },

    deletePlayer(player) {
      console.log('deletePlayer', player)
      this.updateTeam(null, player.email)
    },

    setupTeamData(response) {
      if (response.messages) this.messages = response.messages
      if (response.users) this.users = { ...this.users, ...response.users }
      if (response.team?.validations) this.validations = response.team?.validations
      const leader = response.team?.leader

      let players = {}
      response.team.players.forEach(player => {
        players[player] = this.users[player] ?? { key: player, email: player }
      })

      let invites = []
      if (response.team.invites?.invites) {
        for (const invite of Object.values(response.team.invites.invites).flat()) {
          if (invite.status == 'confirmed') {
            players[invite.user_key] = {
              ...invite,
              ...(this.users[invite.user_key] ?? { key: invite.user_key, email: invite.user_key }),
              invite_key: invite.key,
            }
            continue
          }
          invites.push({
            ...invite,
            ...(this.users[invite.user_key] ?? { key: invite.user_key, email: invite.user_key }),
            invite_key: invite.key,
          })
        }
      }

      players = Object.values(players)
      this.team = {
        ...response.team,
        players,
        invites,
        leader: this.users[leader] ?? { key: leader, email: leader },
      }

      console.log('this.team :>> ', this.team)
    },

    async updateTeam(newPlayerEmail = null, removePlayerEmail = null) {
      let teamData = {
        name: this.team.name,
        logo_key: this.team.logo?.key,
        color: this.team.color,
        players_emails: [],
        leader: this.team.leader?.key ?? this.team.leader?.email,
        submit: true,
      }

      console.log(newPlayerEmail, removePlayerEmail)

      let players = (this.team.players ?? []).concat(this.team.invites ?? []).map(e => e.email)
      if (removePlayerEmail) {
        players = players.filter(el => el != removePlayerEmail)
      }

      if (newPlayerEmail) {
        players.push(newPlayerEmail)
      }

      teamData.players_emails = Array.from(new Set(players))

      utils.tryAndCatch(
        this,
        async () => {
          this.errorMessage = null
          const response = await this.api.updateTeam(this.team.key, teamData)
          this.$buefy.toast.open({
            message: newPlayerEmail ? 'Membro adicionado com sucesso.' : 'Equipa atualizada com sucesso.',
            type: 'is-success',
            position: 'is-top',
          })

          console.log('updateTeam :>> ', response)
          this.setupTeamData(response)
        },
        () => {
          this.saving = false
        },
        error => {
          const errorData = utils.errors(error).get()
          if (errorData?.key == 'InvalidEmail') {
            this.errorMessage = 'E-mail inválido.'
            // Only allow students in academic league. Ignore for local dev
            if (errorData.detail.includes('Only students')) {
              this.errorMessage = 'Apenas podem ser convidados estudantes.'
            }
          }
        }
      )
    },

    submitTeamConfirm() {
      this.$buefy.dialog.confirm({
        confirmText: 'Submeter',
        cancelText: 'Cancelar',
        title: `Submeter inscrição para análise`,
        message: `Tem a certeza que deseja submeter a inscrição da sua equipa?`,
        onConfirm: () => {
          this.submitTeam()
        },
      })
    },

    async submitTeam() {
      if (this.saving) return

      this.saving = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.updateTeamStatus(this.team.key, 'submitted')
          this.$emit('team-submitted')
          this.team.status = response.status
          let invites = []
          if (response.invites?.invites) {
            for (const invite of Object.values(response.invites.invites).flat()) {
              invites.push({
                ...invite,
                ...(this.users[invite.user_key] ?? { key: invite.user_key, email: invite.user_key }),
                invite_key: invite.key,
              })
            }
          }

          this.team.invites = invites

          this.validations = response.validations
        },
        () => {
          this.saving = false
        }
      )
    },

    closeModal() {
      this.isModalActive = false
      this.$v.tmpTeamMember.$reset()
      this.tmpTeamMember = ''
    },

    openModal() {
      this.isModalActive = true
    },

    async resendInvite(invite) {
      console.log('resendInvite', invite)
      utils.tryAndCatch(this, async () => {
        const response = await this.api.resendTeamInvite(invite.invite_key)
        this.$buefy.toast.open({
          duration: 3000,
          message: `Novo convite enviado para <b>${invite.email}</b>`,
          position: 'is-top',
        })
        console.log('resendInvite :>> ', response)

        // Update team
        this.getTeam(true)
      })
    },

    async getTournament() {
      this.loading = true
      try {
        const response = await this.api.getTournament(this.tournamentKey)
        console.log('getTournament :>> ', response)
        this.tournament = response.tournament
        this.getLeagueDetails()
      } catch (error) {
        console.log('Error getTournament :>> ', error)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 750)
      }
    },

    async getLeagueDetails() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getLeagueDetails(this.tournament.edition_key, this.tournament.league_key)
        console.log('getLeagueDetails :>> ', response)
        this.edition = response.edition
        this.league = response.league
        this.sport = response.league.sports.find(el => el.key == this.tournament.sport_key)
      })
    },

    messagesPageChanged(page) {
      console.log('messagesPageChanged :>> ', page)
      this.pagination.page = page
      this.getMessages()
    },

    async addMessage(messageData) {
      console.log('addMessage', messageData)
      utils.tryAndCatch(this, async () => {
        const response = await this.api.sendTeamMessages([this.team.key], messageData.description, messageData.files)
        console.log('addMessage :>> ', response)
        //add user to the list
        if (!this.users?.[this.user.key]) {
          this.users[this.user.key] = this.user
        }
        this.messages.unshift(response)
        this.$buefy.toast.open({
          duration: 3000,
          message: `Mensagem enviada`,
          position: 'is-top',
        })
      })
    },

    async getTeam(silent = false) {
      if (!silent) this.loading = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getTeam(this.teamKey, {
            with_invites: true,
            with_users: true,
          })
          console.log('getTeam :>> ', response)
          if (response.team.chat) {
            this.loadChat(response.team.chat)
            this.chatIsOpen = true
          }
          this.setupTeamData(response)
          if (this.validations.can_see_messages) {
            this.getMessages()
          }
        },
        () => {
          setTimeout(() => {
            this.loading = false
          }, 500)
        }
      )
    },

    async getMessages() {
      this.loadingMessages = true

      utils.tryAndCatch(
        this,
        // Code to run on try
        async () => {
          const response = await this.api.getTeamMessages(this.teamKey, {
            page: this.pagination.page,
            limit: this.pagination.limit,
          })
          console.log('getTeamMessages :>> ', response)
          this.messages = response.messages
          this.users = { ...this.users, ...response.users }

          console.log('this.users :>> ', this.users)

          this.pagination = {
            limit: response.pagination.active_limit,
            page: response.pagination.current_page,
            totalResults: response.pagination.total_items,
            totalPages: response.pagination.total_pages,
          }
        },

        // Finally
        () => {
          this.loadingMessages = false
        }
      )
    },

    cancelTeamConfirm() {
      this.$buefy.dialog.confirm({
        confirmText: 'Cancelar inscrição',
        type: 'is-danger',
        cancelText: 'Sair',
        title: `Cancelar inscrição da equipa`,
        message: `Tem a certeza que deseja cancelar a inscrição da sua equipa?`,
        onConfirm: () => {
          this.cancelTeam()
        },
      })
    },

    async cancelTeam() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.cancelTeamSignUp(this.teamKey)
        console.log('cancelTeamRequest :>> ', response)
        this.$buefy.toast.open({
          duration: 3000,
          message: `Inscrição cancelada com sucesso.`,
          position: 'is-top',
        })
        this.$router.push({ name: 'home' })
      })
    },
  },
}
</script>

<i18n>
  {
    "pt": {
      "noMessages": "Sem mensagens",
      "close": "Fechar",
      "cancel": "Cancelar",
      "delete": "Eliminar",
      "deleteMessageConfirm": "Tem a certeza que deseja eliminar a mensagem?",
      "addMessage": "Adicionar nova mensagem",
      "reply": "Responder",
      "addComplaint": "Adicionar reclamação",
      "notDefined": "Não definido"
    },
    "en": {
      "close": "Close",
      "cancel": "Cancel",
      "delete": "Delete",
      "noMessages": "No messages",
      "addMessage": "Add new message",
      "deleteMessageConfirm": "Are you sure you want to delete the message?",
      "addMessage": "Add new message",
      "reply": "Reply",
      "addComplaint": "Add complaint",
      "notDefined": "Not defined"
    }
  }
  </i18n>
